<template>
  <div class="row back-row">
    <div class="col">
      <a href="/admin/dashboard/">Vissza</a>
    </div>
  </div>
  <div class="container-fluid slide-container slide-manager-container">
    <div class="row main-row">
      <div class="col form-col">
        <div class="img-preview-container">
          <div class="img-preview-wrapper">
            <div>
              <h2>Slide Preview</h2>
              <h4 v-if="activeSlide && activeSlide.name !== undefined">
                {{ activeSlide.name }}
              </h4>
              <h6 v-if="activeSlide && activeSlide.type !== undefined">
                <i>
                  Slider Type:
                  {{ activeSlide.type == "image" ? "Image" : "Video" }}
                </i>
              </h6>
            </div>

            <div class="img-upload-container">
              <input
                type="file"
                id="file-input"
                accept="image/png, image/jpeg, image/jpg, video/mp4"
                @change="addNewFiles"
                multiple
              />
              <label for="file-input">
                <img src="/images/admin/add-logo.png" alt="upload-logo" />
              </label>
              <div class="submit-wrapper">
                <input
                  type="submit"
                  name="upload-images"
                  id="upload-images"
                  v-on:click="uploadSlider"
                />
                <label for="upload-images">
                  <img src="/images/admin/upload-logo.png" alt="upload-logo" />
                </label>
              </div>
            </div>
          </div>

          <div
            class="alert alert-success"
            role="alert"
            v-if="successMessage !== ''"
          >
            {{ successMessage }}
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              v-on:click="removeSuccessMessage"
            ></button>
          </div>

          <div>
            <div class="row">
              <div class="col-12">
                <input
                  type="text"
                  v-model="newYoutubeVideoUrl"
                  placeholder="Paste embed url: "
                />
                <button v-on:click="uploadYoutubeVideo" class="btn btn-primary">
                  Add Youtube Video
                </button>
              </div>
            </div>
          </div>

          <div
            class="alert alert-danger"
            role="alert"
            v-if="errorMessage !== ''"
          >
            {{ errorMessage }}
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              v-on:click="removeErrorMessage"
            ></button>
          </div>
          <div class="image-wrapper row">
            <div
              v-for="(chunk, index) in chunkedSelectedImages"
              v-bind:key="index"
              :class="{
                col: chunkedSelectedImages.length < 2,
                'col-6': chunkedSelectedImages.length == 2,
                'col-4': chunkedSelectedImages.length == 3,
              }"
            >
              <div class="row">
                <div
                  class="col-12"
                  v-for="(item, innerIndex) in chunk"
                  v-bind:key="'inner-' + innerIndex"
                >
                  <small
                    v-if="item"
                    v-on:click="remove(item)"
                    class="close-prev-btn"
                  >
                    X
                  </small>

                  <small
                    v-if="item && !hasNotSavedImage"
                    v-on:click="changeOrder(item, 'up')"
                    class="order-button order-up"
                  >
                    >
                  </small>
                  <small
                    v-if="item && !hasNotSavedImage"
                    v-on:click="changeOrder(item, 'down')"
                    class="order-button order-down"
                  >
                    >
                  </small>
                  <small
                    v-if="item && !hasNotSavedImage"
                    v-on:click="changeOrder(item, 'left')"
                    class="order-button order-left"
                  >
                    >
                  </small>
                  <small
                    v-if="item && !hasNotSavedImage"
                    v-on:click="changeOrder(item, 'right')"
                    class="order-button order-right"
                  >
                    >
                  </small>

                  <figure v-if="item && item.url && item.type == 'image'">
                    <img :src="item.url" class="img-fluid" />
                  </figure>

                  <video
                    v-if="
                      item &&
                      item.url &&
                      item.type == 'video' &&
                      item.youtubeEmbed == null
                    "
                    autoplay
                    loop
                    muted
                  >
                    <source :src="item.url" :type="item.mimeType" />

                    Your browser can't play this video!
                  </video>

                  <iframe
                    :ref="
                      (el) => {
                        iframeRef[item.id] = el;
                      }
                    "
                    class="youtube-embed"
                    v-if="item && item.youtubeEmbed && item.type == 'video'"
                    :src="
                      item.youtubeEmbed + '?autoplay=1&controls=0&loop=1&mute=1'
                    "
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; autoplay; controls; loop; mute"
                    :height="calcHeight(item)"
                  >
                  </iframe>

                  <input
                    v-if="item && item.name"
                    type="text"
                    :value="item.name"
                    v-on:change="changeImageName($event, item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-flex flex-column slider-preview-modal mt-5">
        <div class="col col-md-12">
          <div
            class="form-group admin-img-container"
            id="admin-img-container"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<!--<iframe width="560" height="315" src="https://www.youtube.com/embed/WZ6XcmvPdQw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

<script>
import { defineComponent, ref, onBeforeUpdate } from "vue";
import axios from "axios";

export default defineComponent({
  name: "slide-manager",

  props: {
    sliderId: String,
  },
  setup() {
    const iframeRef = ref({});

    return {
      iframeRef,
    };
  },
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      selectedImages: [],
      sliderName: "-",
      activeSlide: {
        name: "-",
        type: "-",
      },
      newYoutubeVideoUrl: "",
    };
  },
  mounted() {
    this.prepareSlider();
  },
  methods: {
    calcHeight(item) {
      const el = this.iframeRef[item.id];
      if (el) {
        let height = (9 / 16) * el.offsetWidth;
        return height < 200 ? 200 : height;
      }
      return 200;
    },
    uploadYoutubeVideo() {
      axios
        .post(`/admin/slider-api/slider/${this.sliderId}/youtube`, {
          videoEmbed: this.newYoutubeVideoUrl,
        })
        .then((res) => {
          this.selectedImages = res.data.slider;
          this.successMessage = "Sikeresen frissítetted az adatokat!";
          this.newYoutubeVideoUrl = "";
        })
        .catch((err) => {
          console.err("err", err);
          this.errorMesage = "Hiba történt az adatok frissítése közben!";
        });
    },
    addNewFiles(e) {
      e.preventDefault();

      for (var i = 0; i < e.target.files.length; i++) {
        let filteredImages = this.selectedImages.filter((item) => {
          return item && item.id !== undefined;
        });
        let file = e.target.files.item(i);
        let fileType = null;

        try {
          fileType = file.type.split("/")[0];
        } catch (e) {}

        if (this.activeSlide.type == "video" && filteredImages.length < 4) {
          this.selectedImages.push({
            id: this.generateUUID(),
            url: URL.createObjectURL(file),
            originalFile: file,
            name: file.name,
            origin: "client",
            type: fileType == null ? "video" : fileType,
            mimeType: file.type,
          });
        } else if (
          this.activeSlide.type == "image" &&
          filteredImages.length < 9
        ) {
          this.selectedImages.push({
            id: this.generateUUID(),
            url: URL.createObjectURL(file),
            originalFile: file,
            name: file.name,
            origin: "client",
            type: fileType == null ? "image" : fileType,
            mimeType: file.type,
          });
        }
      }
    },

    prepareSlider() {
      axios
        .get(`/admin/slider-api/slide/${this.sliderId}`)
        .then((res) => {
          this.activeSlide = res.data.slide;
        })
        .catch((error) => {
          console.error("O'ops", error);
        });

      axios
        .get(`/admin/slider-api/slider/${this.sliderId}/images`)
        .then((res) => {
          this.selectedImages = res.data.slider;
        })
        .catch((error) => {
          console.error("O'ops", error);
        });
    },

    uploadSlider(e) {
      e.preventDefault();

      this.removeErrorMessage();
      this.removeSuccessMessage();

      let formData = new FormData();
      let size = 0;
      this.selectedImages.forEach((image, index) => {
        if (image.origin == "client") {
          console.log("Client Image", image.originalFile.size);

          size = size + image.originalFile.size;
          formData.append(`images[${index}]`, image.originalFile);
          formData.append(`name[${index}]`, image.name);
        } else {
          formData.append(`newName[${image.id}]`, image.name);
        }

        length++;
      });
      console.log("size", size / 1024);
      if (size >= 1000 * 1024 * 1024) {
        this.errorMessage =
          "Túl nagy fájlokat töltesz fel, próbáld egyenként, vagy kisebb méretűeket! Maximum együttes fájlméret: 1000MB, Amit már feltöltöttél, azt már nem kell számolni. ";
        return;
      }

      axios
        .post(`/admin/slider-api/slider/${this.sliderId}/images`, formData)
        .then((res) => {
          this.selectedImages = res.data.slider;
          this.successMessage = "Sikeresen frissítetted az adatokat!";
        })
        .catch((err) => {
          console.err("err", err);
          this.errorMesage = "Hiba történt az adatok frissítése közben!";
        });

      //TODO: add slider
    },

    changeImageName(event, image) {
      let currentImage = this.selectedImages.find((findImage) => {
        return findImage.id == image.id;
      });

      currentImage.name = event.target.value;
    },

    remove(image) {
      if (image.origin == "client") {
        let index = this.selectedImages.findIndex((findImage) => {
          return image.id == findImage.id;
        });

        if (index != -1) {
          this.selectedImages.splice(index, 1);
        }

        return;
      }

      axios
        .delete(`/admin/slider-api/slider/image/${image.id}`)
        .then(() => {
          let index = this.selectedImages.findIndex((findImage) => {
            return image.id == findImage.id;
          });

          if (index != -1) {
            this.selectedImages.splice(index, 1);
          }

          //TODO: success message
        })
        .catch((error) => {
          console.error("error", error);
          //TODO: error message
        });
    },

    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },

    removeSuccessMessage() {
      this.successMessage = "";
    },

    removeErrorMessage() {
      this.errorMessage = "";
    },

    changeOrder(image, direction) {
      let selectedArrayMax = 3;
      if (this.selectedImages.length < 5) {
        selectedArrayMax = 2;
      }

      let order = image.order;

      if (direction == "up") {
        order = order - selectedArrayMax;
      }
      if (direction == "down") {
        order = order + selectedArrayMax;
      }
      if (direction == "left") {
        order = order - 1;
      }
      if (direction == "right") {
        order = order + 1;
      }

      axios
        .put(`/admin/slider-api/slide/image/order/${image.id}`, {
          order: order,
        })
        .then((res) => {
          this.selectedImages = res.data.slider;
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
  },
  computed: {
    hasNotSavedImage() {
      return (
        this.selectedImages.findIndex((image, index) => {
          return image.origin == "client";
        }) != -1
      );
    },
    chunkedSelectedImages() {
      let output = [];

      let selectedArrayMax = 3;
      if (this.selectedImages.length < 5) {
        selectedArrayMax = 2;
      }

      for (let i = 0; i < selectedArrayMax; i++) {
        output[i] = [];
      }

      if (this.selectedImages !== undefined) {
        let selectedArray = 0;

        this.selectedImages.forEach((image, index) => {
          output[selectedArray].push(image);
          selectedArray++;
          if (selectedArray >= selectedArrayMax) {
            selectedArray = 0;
          }
        });
      }

      return output;
    },
  },
});
</script>
