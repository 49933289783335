<template>
  <form
    method="post"
    name="reset"
    class="p-5 d-flex flex-column align-items-center login-form"
  >
    <h5 class="text-white text-center">
      Enter e-mail address to send password link
    </h5>

    <input
      type="text"
      name="email"
      class="form-control mt-4"
      placeholder="E-MAIL"
      required
      v-model="email"
    />

    <div v-if="success" class="alert alert-success" role="alert">
      Kérlek ellenőrizd a postafiókodat, a jelszó emlékeztető linket elküldtük!
    </div>

    <div v-if="errors !== undefined" class="alert alert-danger" role="alert">
      {{ errors }}
    </div>

    <input
      type="submit"
      name="submit_email"
      class="admin-btn btn w-100 mt-4"
      v-on:click="resetSend"
    />
  </form>
</template>

<script>
import { defineComponent, ref, onBeforeUpdate } from "vue";
import axios from "axios";

export default defineComponent({
  name: "get-reset-password-form",

  props: {},
  data() {
    return {
      email: "",
      success: false,
      errors: undefined,
    };
  },
  mounted() {},
  methods: {
    /**
     * This method will send a password request for the given user
     */
    resetSend(event) {
      event.preventDefault();
      this.errors = undefined;
      this.success = false;

      axios
        .post("/login/forget-password", {
          email: this.email,
        })
        .then((res) => {
          this.success = true;
        })
        .catch((err) => {
          this.errors = "Nem sikerül resetelni a jelszavad!";
        });
    },
  },
});
</script>
