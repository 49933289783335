<template>
  <form method="post" action="" name="update">
    <div class="form-group">
      <label><strong>Enter New Password:</strong></label>
      <input
        type="password"
        name="pass1"
        class="form-control"
        v-model="password"
      />
    </div>

    <div class="form-group">
      <label><strong>Re-Enter New Password:</strong></label>
      <input
        type="password"
        name="pass2"
        class="form-control"
        v-model="rePassword"
      />
    </div>

    <div v-if="success" class="alert alert-success" role="alert">
         A jelszavad sikeresen frissült!  Kérlek lépj be <a href="/login">Itt</a>
    </div>

    <div v-if="errors !== undefined" class="alert alert-danger" role="alert">
      {{ errors }}
    </div>

    <div class="form-group">
      <input
        type="submit"
        id="reset"
        value="Reset Password"
        class="btn btn-primary"
        v-on:click="resetPassword"
      />
    </div>
  </form>
</template>

<script>
import { defineComponent, ref, onBeforeUpdate } from "vue";
import axios from "axios";

export default defineComponent({
  name: "reset-password-form",

  props: {},
  data() {
    return {
      password: "",
      rePassword: "",
      success: false,
      errors: undefined,
    };
  },
  mounted() {},
  methods: {
    /**
     * This method will send a password request for the given user
     */
    resetPassword(event) {
      event.preventDefault();

      this.errors = undefined;
      this.success = false;
      const queryString = window.location.search;

      const urlParams = new URLSearchParams(queryString);

      axios
        .post("/login/reset-password", {
          password: this.password,
          rePassword: this.rePassword,
          token: urlParams.get("token"),
        })
        .then((res) => {
          this.success = true;
        })
        .catch((err) => {
          
          console.log("err", err);

          this.errors = "Nem sikerül resetelni a jelszavad!";
        });
    },
  },
});
</script>
