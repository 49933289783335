import "bootstrap";
import { createApp } from "vue";
import Login from "./components/Admin/Login.vue"
import GetResetPassword from "./components/Admin/GetResetPassword.vue"
import ResetPassword from "./components/Admin/ResetPassword.vue"
import SlideManager from "./components/Admin/SlideManager.vue"
import SlideSelector from "./components/Admin/SlideSelector.vue"



if (document.getElementById("admin-login-wrapper") !== null) {
    createApp({
        components: {
            "login-form": Login,
            "get-reset-password-form": GetResetPassword,
            'reset-password-form': ResetPassword,
        }
    }).mount("#admin-login-wrapper");
}


if (document.getElementById("admin") !== null) {
    const app = createApp({
        components: {
            'slide-manager': SlideManager,
            'slide-selector': SlideSelector,
        }
    })
    app.mount("#admin")
}

