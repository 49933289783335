<template>
  <div class="container-fluid slide-container slide-selector-container">
    <div class="row main-row d-flex">
      <div class="col form-col">
        <div class="slide" v-for="slide in slides" v-bind:key="slide.id">
          <span>{{ slide.name }}</span>

          <span class="type">{{ slide.type }}</span>

          <a
            :href="`/admin/dashboard/slider/${slide.id}`"
            class="btn admin-btn"
          >
            {{ slide.hasContent ? "Edit" : "Upload" }}
          </a>

          <button class="btn admin-btn" v-on:click="deleteSlide(slide)">
            Delete
          </button>
        </div>

        <div class="slide">
          <div class="enter-slide">
            <input
              type="text"
              placeholder="Enter slide name..."
              v-model="newSlideName"
            />
          </div>
          <div class="type-options-wrapper">
            <div class="type-options">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  value="image"
                  checked
                  v-model="newType"
                />

                <label class="form-check-label" for="flexRadioDefault1">
                  Image
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  value="video"
                  v-model="newType"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Video
                </label>
              </div>
            </div>
            <button class="btn admin-btn" v-on:click="addSlide">
              Add New Slide
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeUpdate } from "vue";
import axios from "axios";

export default defineComponent({
  name: "slide-selector",

  props: {},
  data() {
    return {
      newSlideName: "",
      newType: "image",
      slides: [],
    };
  },
  mounted() {
    this.listSlides();
  },
  methods: {
    listSlides() {
      axios
        .get("/admin/slider-api/slides")
        .then((res) => {
          this.slides = res.data.slides;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    deleteSlide(slide) {
      axios
        .delete(`/admin/slider-api/slide/${slide.id}`)
        .then((res) => {
          this.slides = res.data.slides;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    addSlide() {
      let newSlide = {
        name: this.newSlideName,
        type: this.newType,
      };

      axios
        .post(`/admin/slider-api/slide`, newSlide)
        .then((res) => {
          this.newSlideName = "";
          this.slides = res.data.slides;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateSlide(slide) {
      axios
        .post(`/admin/slider-api/slide/${slide.id}`, slide)
        .then((res) => {
          this.slides = res.data.slides;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
});
</script>
