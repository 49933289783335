<template>
  <form
    method="post"
    class="p-5 d-flex flex-column align-items-center login-form"
  >
    <input
      type="text"
      name="email"
      placeholder="E-MAIL"
      class="form-control"
      required
      v-model="email"
    />
    <input
      type="password"
      name="password"
      placeholder="PASSWORD"
      class="form-control mt-4"
      required
      v-model="password"
    />  

    <div v-if="errors!==undefined" class="alert alert-danger" role="alert">
        {{errors}}
    </div>

    <button name="login" class="admin-btn btn w-100 mt-4" v-on:click="login">
      LOGIN
    </button>

    <a href="/login/forget-password" class="mt-4">
      <span>Forgotten password?</span>
    </a>
  </form>
</template>

<script>
import { defineComponent, ref, onBeforeUpdate } from "vue";
import axios from "axios";

export default defineComponent({
  name: "login-form",
  //setup() {
  //    const videoRef = ref(null);
  //
  //    onBeforeUpdate(() => {
  //        videoRef.value = null;
  //    });
  //
  //    return {
  //        videoRef,
  //    };
  //},
  props: {},
  data() {
    return {
      email: "",
      password: "",
      errors:undefined
    };
  },
  mounted() {},
  methods: {
    /**
     * This method will login the user
     */
    login(event) {
     event.preventDefault();
        this.errors=undefined;
      axios
        .post("/login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
            if(res.data.redirect !== undefined){
                window.location.replace(res.data.redirect)
            }
        })
        .catch((err) => {
                    this.errors="Nem sikerül belépned!";
        });
    },
  },
});
</script>
